import React, { useState, useEffect } from 'react';
import axios from 'axios';
import PocketBase from 'pocketbase';
import { CheckCircleIcon, ClipboardDocumentIcon, UserIcon } from '@heroicons/react/24/outline';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper-bundle.css';

const pb = new PocketBase('https://pocketbase.fullbread.ru/');

const Example = () => {
    const [publicServerData, setPublicServerData] = useState({
        name: 'Loading...',
        map: 'Loading...',
        players: 'Loading...',
        max_players: 'Loading...',
    });

    const [moddedServerData, setModdedServerData] = useState({
        name: 'Loading...',
        map: 'Loading...',
        players: 'Loading...',
        max_players: 'Loading...',
    });

    const [hardcoreServerData, setHardcoreServerData] = useState({
        name: 'Loading...',
        map: 'Loading...',
        players: 'Loading...',
        max_players: 'Loading...',
    });

    const [bomjServerData, setBomjServerData] = useState({
        name: 'Loading...',
        map: 'Loading...',
        players: 'Loading...',
        max_players: 'Loading...',
    });

    const [maps, setMaps] = useState([]);
    const [error, setError] = useState(null);
    const [isCopiedPublic, setIsCopiedPublic] = useState(false);
    const [isCopiedModded, setIsCopiedModded] = useState(false);
    const [isCopiedHardcore, setIsCopiedHardcore] = useState(false);
    const [isCopiedBomj, setIsCopiedBomj] = useState(false);
    const [ipPublic] = useState('62.122.213.126:27102');
    const [ipModded] = useState('62.122.213.126:27202');
    const [ipHard] = useState('62.122.213.126:27302');
    const [ipBomj] = useState('62.122.213.126:27402');
    const [results, setResults] = useState([]);
    const [topWeekly, setTopWeekly] = useState([]);
    const [topMonthly, setTopMonthly] = useState([]);
    const [avatars, setAvatars] = useState({});
    const [activeTab, setActiveTab] = useState('Активность'); // Состояние для активного таба
    const [loadingWeekly, setLoadingWeekly] = useState(false); // Состояние для загрузки Топа недели
    const [loadingMonthly, setLoadingMonthly] = useState(false); // Состояние для загрузки Топа недели
    const [top_donations, setTopDontaions] = useState([]);
    const [last_donations, setLastDontaions] = useState([]);
    const handleTabClick = (tabName) => {
        setActiveTab(tabName); // Изменить активный таб
    };

    const getStats = async () => {
        try {
            const response = await pb.collection('stats_view').getList(1, 100, {
                sort: '-date_time'
            });

            setResults(response.items);

        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    const getTopDonations = async () => {
        try {
            const response = await pb.collection('donations_top').getList(1, 100, {
            });

            setTopDontaions(response.items);

        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    const getLastDonations = async () => {
        try {
            const response = await pb.collection('donations_last').getList(1, 100, {
            });

            setLastDontaions(response.items);

        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };


    const getTopWeekly = async () => {
        try {
            const response = await pb.collection('stats_top_weekly').getList(1, 100, {
            });

            setTopWeekly(response.items);

        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    const getTopMonthly = async () => {
        try {
            const response = await pb.collection('stats_top_monthly').getList(1, 100, {
            });

            setTopMonthly(response.items);

        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    const fetchAvatars = async (players) => {
        for (const player of players) {
            if (!avatars[player.platform_id] && player.platform_id.startsWith('SteamNWI:')) {
                // Задержка в 100 мс перед каждым запросом
                await new Promise(resolve => setTimeout(resolve, 100));

                const steamData = await getSteamData(player.platform_id);
                if (steamData) {
                    setAvatars((prevAvatars) => ({
                        ...prevAvatars,
                        [player.platform_id]: {
                            avatarUrl: steamData.avatarmedium,
                            profileUrl: steamData.profileurl,
                        },
                    }));
                }
            }
        }
    };

    useEffect(() => {
        const fetchPublicServerData = async () => {
            try {
                const response = await axios.get('https://request.fullbread.ru/server-info/public', {
                    headers: {
                        'Cache-Control': 'no-cache'
                    }
                });
                const data = response.data;

                setPublicServerData({
                    name: data.name,
                    map: data.map,
                    players: data.players,
                    max_players: data.max_players,
                });
            } catch (err) {
                console.error('Error fetching public server data:', err);
                setPublicServerData({
                    name: 'Error loading data',
                    map: 'Error loading data',
                    players: 'Error loading data',
                    max_players: 'Error loading data',
                });
            }
        };

        const fetchModdedServerData = async () => {
            setTimeout(async () => {
                try {
                    const response = await axios.get('https://request.fullbread.ru/server-info/modded', {
                        headers: {
                            'Cache-Control': 'no-cache'
                        }
                    });
                    const data = response.data;

                    setModdedServerData({
                        name: data.name,
                        map: data.map,
                        players: data.players,
                        max_players: data.max_players,
                    });
                } catch (err) {
                    console.error('Error fetching modded server data:', err);
                    setModdedServerData({
                        name: 'Error loading data',
                        map: 'Error loading data',
                        players: 'Error loading data',
                        max_players: 'Error loading data',
                    });
                }
            }, 500);
        };

        const fetchHardcoreServerData = async () => {
            setTimeout(async () => {
                try {
                    const response = await axios.get('https://request.fullbread.ru/server-info/hardcore', {
                        headers: {
                            'Cache-Control': 'no-cache'
                        }
                    });
                    const data = response.data;

                    setHardcoreServerData({
                        name: data.name,
                        map: data.map,
                        players: data.players,
                        max_players: data.max_players,
                    });
                } catch (err) {
                    console.error('Error fetching hardcore server data:', err);
                    setHardcoreServerData({
                        name: 'Error loading data',
                        map: 'Error loading data',
                        players: 'Error loading data',
                        max_players: 'Error loading data',
                    });
                }
            }, 500);
        };

        const fetchBomjServerData = async () => {
            setTimeout(async () => {
                try {
                    const response = await axios.get('https://request.fullbread.ru/server-info/bomj', {
                        headers: {
                            'Cache-Control': 'no-cache'
                        }
                    });
                    const data = response.data;

                    setBomjServerData({
                        name: data.name,
                        map: data.map,
                        players: data.players,
                        max_players: data.max_players,
                    });
                } catch (err) {
                    console.error('Error fetching bomj server data:', err);
                    setBomjServerData({
                        name: 'Error loading data',
                        map: 'Error loading data',
                        players: 'Error loading data',
                        max_players: 'Error loading data',
                    });
                }
            }, 500);
        };

        const fetchMaps = async () => {
            try {
                const result = await pb.collection('get_maps').getList(1, 50);
                setMaps(result.items);
            } catch (err) {
                setError(err.message);
            }
        };



        fetchPublicServerData();
        setTimeout(() => {
            getTopDonations();

        }, 20);
        setTimeout(() => {
            getLastDonations();

        }, 40);
        setTimeout(() => {
            fetchHardcoreServerData();
        }, 200);
        setTimeout(() => {
            fetchBomjServerData();
        }, 250);
        setTimeout(() => {
            fetchMaps();
        }, 300);
        setTimeout(() => {
            getStats();
        }, 500);


    }, []);


    useEffect(() => {
        fetchAvatars(results);  // вызовите fetchAvatars только когда results изменится  
    }, [results]);

    useEffect(() => {
        fetchAvatars(topWeekly);  // вызовите fetchAvatars только когда results изменится  
    }, [topWeekly]);
    useEffect(() => {
        fetchAvatars(topMonthly);  // вызовите fetchAvatars только когда results изменится  
    }, [topMonthly]);

    const handleCopyToClipboard = async (ip) => {
        try {
            await navigator.clipboard.writeText(ip);
            setIsCopiedPublic(true);
            setTimeout(() => {
                setIsCopiedPublic(false);
            }, 2000);
        } catch (error) {
            console.error('Ошибка копирования в буфер обмена:', error);
        }
    };

    const handleCopyToClipboardModded = async (ip) => {
        try {
            await navigator.clipboard.writeText(ip);
            setIsCopiedModded(true);
            setTimeout(() => {
                setIsCopiedModded(false);
            }, 2000);
        } catch (error) {
            console.error('Ошибка копирования в буфер обмена:', error);
        }
    };

    const handleCopyToClipboardHardcore = async (ip) => {
        try {
            await navigator.clipboard.writeText(ip);
            setIsCopiedHardcore(true);
            setTimeout(() => {
                setIsCopiedHardcore(false);
            }, 2000);
        } catch (error) {
            console.error('Ошибка копирования в буфер обмена:', error);
        }
    };
    const handleCopyToClipboardBomj = async (ip) => {
        try {
            await navigator.clipboard.writeText(ip);
            setIsCopiedBomj(true);
            setTimeout(() => {
                setIsCopiedBomj(false);
            }, 2000);
        } catch (error) {
            console.error('Ошибка копирования в буфер обмена:', error);
        }
    };
    const getSteamData = async (steamID) => {
        try {
            const response = await axios.post(
                'https://steamdata.fullbread.ru/steam-users',
                { steamIds: [steamID.split(':')[1]] }
            );

            const playerData = response.data[0];
            return playerData;
        } catch (error) {
            console.error('Error fetching Steam data:', error);
            return null;
        }
    };
    const formatDateTime = (dateString: string) => {
        const date = new Date(dateString);

        // Создаём словарь для сокращённых названий месяцев
        const shortMonthNames = [
            'янв.', 'февр.', 'март', 'апр.', 'май', 'июнь',
            'июль', 'авг.', 'сент.', 'окт.', 'нояб.', 'дек.'
        ];

        const day = date.getUTCDate(); // День месяца (UTC)
        const month = shortMonthNames[date.getUTCMonth()]; // Получаем сокращённое название месяца
        const hours = String(date.getUTCHours()).padStart(2, '0'); // Часы (UTC)
        const minutes = String(date.getUTCMinutes()).padStart(2, '0'); // Минуты (UTC)

        return `${day} ${month} ${hours}:${minutes}`; // Возвращаем строку в формате "2 окт. 16:20"
    };

    const getMapImagePath = (map) => {
        if (!map) {
            return '';
        }

        const collectionId = map.collectionId;
        const recordId = map.id;
        const fileName = map.preview;

        return `https://pocketbase.fullbread.ru/api/files/${collectionId}/${recordId}/${fileName}`;
    };

    const findMapByCode = (mapCode) => {
        return maps.find((map) => map.code === mapCode);
    };

    useEffect(() => {
        if (activeTab === 'Активность') {
            // fetchAvatars(results); // Загрузить аватары для "Активности"
        } else if (activeTab === 'Топ недели') {
            getTopWeekly(); // Загрузка "Топа недели" при переключении
            //fetchAvatars(topWeekly); // Загрузить аватары для "Топа недели"
        } else if (activeTab === 'Топ месяца') {
            getTopMonthly(); // Загрузка "Топа недели" при переключении
            //fetchAvatars(topMonthly); // Загрузить аватары для "Топа недели"
        }
    }, [activeTab]);


    const tabs = [
        { name: 'Активность', href: '#', current: true },
        { name: 'Топ недели', href: '#', current: false },
        { name: 'Топ месяца', href: '#', current: false },
    ]

    function classNames(...classes) {
        return classes.filter(Boolean).join(' ')
    }

    const DonationSlider = ({ title, data }) => {
        return (
            <div className="container mx-auto pt-4 px-5">
                <h2 className="flex  md:flex-row flex-col items-center text-1xl mb-0 font-medium text-yellowcustom">{title} <div className="pl-1 tooltip cursor-pointer"><svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="18" height="18" viewBox="0,0,256,256">
                    <g fill="#ffffff" fillRule="nonzero" stroke="none" strokeWidth="1" strokeLinecap="butt" strokeLinejoin="miter" strokeMiterlimit="10" strokeDasharray="" strokeDashoffset="0" fontFamily="none" fontWeight="none" fontSize="none" textAnchor="none">
                        <g transform="scale(5.33333,5.33333)">
                            <path d="M24,4c-11.02793,0 -20,8.97207 -20,20c0,11.02793 8.97207,20 20,20c11.02793,0 20,-8.97207 20,-20c0,-11.02793 -8.97207,-20 -20,-20zM24,7c9.40662,0 17,7.59339 17,17c0,9.40661 -7.59338,17 -17,17c-9.40661,0 -17,-7.59339 -17,-17c0,-9.40661 7.59339,-17 17,-17zM24,14c-1.10457,0 -2,0.89543 -2,2c0,1.10457 0.89543,2 2,2c1.10457,0 2,-0.89543 2,-2c0,-1.10457 -0.89543,-2 -2,-2zM23.97656,20.97852c-0.82766,0.01293 -1.48843,0.69381 -1.47656,1.52148v11c-0.00765,0.54095 0.27656,1.04412 0.74381,1.31683c0.46725,0.27271 1.04514,0.27271 1.51238,0c0.46725,-0.27271 0.75146,-0.77588 0.74381,-1.31683v-11c0.00582,-0.40562 -0.15288,-0.7963 -0.43991,-1.08296c-0.28703,-0.28666 -0.67792,-0.44486 -1.08353,-0.43852z"></path>
                        </g>
                    </g>
                </svg>
                    <span className="tooltiptext">Список заполняется вручную, через некоторое время после отправки доната</span>
                </div></h2>
                <Swiper
                    spaceBetween={2}
                    slidesPerView={2}
                    breakpoints={{
                        640: {
                            slidesPerView: 2,
                            spaceBetween: 2,
                        },
                        1024: {
                            slidesPerView: 6,
                            spaceBetween: 2,
                        },
                    }}
                >
                    {data.map((donation) => (
                        <SwiperSlide key={donation.id}>
                            <div className="text-white">
                                <div className="font-bold text-lg">{donation.name} - {donation.amount}₽</div>

                            </div>
                        </SwiperSlide>
                    ))}
                </Swiper>
            </div >
        );
    };

    const renderPlayers = (players) => {
        return players.map((player) => (
            <li key={player.ID}>
                <div className="relative pb-2 pl-2 pr-2 pt-3">
                    <div className="relative flex space-x-3 items-center">
                        <div>
                            {avatars[player.platform_id] ? (
                                <a href={avatars[player.platform_id].profileUrl} target="_blank" rel="noopener noreferrer">
                                    <img
                                        src={avatars[player.platform_id].avatarUrl}
                                        alt="Steam Avatar"
                                        className="h-8 w-8 rounded-full"
                                    />
                                </a>
                            ) : (
                                <UserIcon className="ml-1 h-5 w-5 text-white" aria-hidden="true" />
                            )}
                        </div>
                        <div className="flex min-w-0 flex-1 justify-between space-x-1 items-center">
                            <div className="text-sm">
                                {avatars[player.platform_id] ? (
                                    <a href={avatars[player.platform_id].profileUrl} target="_blank" className="font-medium text-white break-all">
                                        {player.name}
                                    </a>
                                ) : (
                                    <span className="font-medium text-white break-all">{player.name}</span>
                                )}
                            </div>
                            <div className="text-sm border rounded-xl p-1 bg-gray-200">
                                {player.server || player.total_stats}
                            </div>
                            <div className="whitespace-nowrap text-right text-sm text-white">
                                {formatDateTime(player.date_time)}
                            </div>
                        </div>
                    </div>
                </div>
            </li>
        ));
    };

    const renderTop = (players) => {
        return players.map((player, index) => (


            <li key={player.ID}>

                <div className="relative pb-2 pl-2 pr-2 pt-3">
                    <div className="relative flex space-x-3 items-center">
                        <div className="text-sm text-white font-bold w-6 text-center">
                            {index + 1}
                        </div>
                        <div>
                            {avatars[player.platform_id] ? (
                                <a href={avatars[player.platform_id].profileUrl} target="_blank" rel="noopener noreferrer">
                                    <img
                                        src={avatars[player.platform_id].avatarUrl}
                                        alt="Steam Avatar"
                                        className="h-8 w-8 rounded-full"
                                    />
                                </a>
                            ) : (
                                <UserIcon className="ml-1 h-5 w-5 text-white" aria-hidden="true" />
                            )}
                        </div>
                        <div className="flex min-w-0 flex-1 justify-between space-x-1 items-center">
                            <div className="text-sm">
                                {avatars[player.platform_id] ? (
                                    <a href={avatars[player.platform_id].profileUrl} target="_blank" className="font-medium text-white break-all">
                                        {player.name}
                                    </a>
                                ) : (
                                    <span className="font-medium text-white break-all">{player.name}</span>
                                )}
                            </div>
                            <div className="text-sm p-1 text-white  text-center">
                                {player.total_stats}
                            </div>
                            <div className="whitespace-nowrap text-right text-sm text-white">
                                {formatDateTime(player.date_time)}
                            </div>
                        </div>
                    </div>
                </div>
            </li>
        ));
    };

    const renderTopHeader = () => {
        return (

            <li>
                <div className="relative pb-2 pl-2 pr-2 pt-3"><div className="relative flex space-x-3 items-center">
                    <div className="pl-2 font-medium text-white">#</div>
                    <div></div>
                    <div className="flex min-w-0 flex-1 justify-between space-x-1 items-center">
                        <div className="text-sm">
                            <span className="font-medium text-white">
                                Игрок
                            </span>
                        </div>
                        <div className="text-sm p-1 text-white text-right flex items-center">

                            Показатель <div className="pl-1 tooltip cursor-pointer"><svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="18" height="18" viewBox="0,0,256,256">
                                <g fill="#ffffff" fillRule="nonzero" stroke="none" strokeWidth="1" strokeLinecap="butt" strokeLinejoin="miter" strokeMiterlimit="10" strokeDasharray="" strokeDashoffset="0" fontFamily="none" fontWeight="none" fontSize="none" textAnchor="none">
                                    <g transform="scale(5.33333,5.33333)">
                                        <path d="M24,4c-11.02793,0 -20,8.97207 -20,20c0,11.02793 8.97207,20 20,20c11.02793,0 20,-8.97207 20,-20c0,-11.02793 -8.97207,-20 -20,-20zM24,7c9.40662,0 17,7.59339 17,17c0,9.40661 -7.59338,17 -17,17c-9.40661,0 -17,-7.59339 -17,-17c0,-9.40661 7.59339,-17 17,-17zM24,14c-1.10457,0 -2,0.89543 -2,2c0,1.10457 0.89543,2 2,2c1.10457,0 2,-0.89543 2,-2c0,-1.10457 -0.89543,-2 -2,-2zM23.97656,20.97852c-0.82766,0.01293 -1.48843,0.69381 -1.47656,1.52148v11c-0.00765,0.54095 0.27656,1.04412 0.74381,1.31683c0.46725,0.27271 1.04514,0.27271 1.51238,0c0.46725,-0.27271 0.75146,-0.77588 0.74381,-1.31683v-11c0.00582,-0.40562 -0.15288,-0.7963 -0.43991,-1.08296c-0.28703,-0.28666 -0.67792,-0.44486 -1.08353,-0.43852z"></path>
                                    </g>
                                </g>

                            </svg>
                                <span className="tooltiptext">Каждые 5 минут происходит сбор информациии о текущих игроках на сервере, количество вхождений отражает показатель</span>
                            </div></div>
                        <div className="whitespace-nowrap  text-center text-sm text-white">Онлайн</div>
                    </div>
                </div>
                </div>
            </li>

        );
    };

    return (
        <section className="body-font bg-darker-light">
            <DonationSlider title="Топ донатеров" data={top_donations} />
            <DonationSlider title="Последние" data={last_donations} />


            <div className="container mx-auto flex flex-wrap">

                <div className="mb-8">

                    <h1 className="container mx-auto flex px-5 pt-12 md:flex-row flex-col items-center title-font text-3xl mb-0 font-medium text-white">Статус серверов</h1>

                    {/* Блок с публичным сервером */}
                    <div className="flex px-5 pt-8 md:flex-row flex-col items-center  max-w-4xl">
                        <div className="lg:flex-grow md:w-1/2 md:pr-16 flex flex-col md:items-start md:text-left mb-16 md:mb-0 items-center md:w-full">
                            <p className="font-semibold    text-white w-full">Название: <span className="font-light">{publicServerData.name}</span> </p>
                            <p className="font-semibold    text-white w-full">Карта: <span className="font-light">{findMapByCode(publicServerData.map)?.name ?? publicServerData.map}</span></p>
                            <p className="font-semibold    text-white w-full mb-4">Режим: <span className="font-light">{findMapByCode(publicServerData.map)?.mode ?? ''}</span></p>

                            {/* Кнопка копирования IP */}
                            <div className="flex flex-col sm:flex-row items-center mb-4">
                                <input
                                    type="text"
                                    readOnly
                                    className="border rounded-lg p-2 bg-white text-gray-700 w-full sm:w-auto text-center mb-2 sm:mb-0"
                                    value={ipPublic}
                                />
                                <button
                                    className="bg-gray-100 inline-flex py-2 px-5 rounded-lg items-center hover:bg-gray-200 focus:outline-none sm:ml-2"
                                    onClick={() => handleCopyToClipboard(ipPublic)}
                                >
                                    {isCopiedPublic ? (
                                        <>
                                            <CheckCircleIcon className="w-6 h-6 text-green-500" />
                                            <span className="ml-4 text-green-500">Скопировано!</span>
                                        </>
                                    ) : (
                                        <>
                                            <ClipboardDocumentIcon className="w-6 h-6" />
                                            <span className="ml-4">Скопировать</span>
                                        </>
                                    )}
                                </button>
                            </div>
                        </div>

                        {/* Изображение карты для публичного сервера */}
                        <div className="lg:max-w-52 lg:w-full md:w-full w-3/6">
                            <img
                                className="object-cover object-center rounded"
                                src={getMapImagePath(findMapByCode(publicServerData.map))}
                                alt={findMapByCode(publicServerData.map)?.name ?? ''}
                            />
                        </div>
                    </div>

                    {/* Блок с хардкорным сервером */}
                    <div className="flex px-5 pt-4 md:flex-row flex-col items-center  max-w-4xl">
                        <div className="lg:flex-grow md:w-1/2 md:pr-16 flex flex-col md:items-start md:text-left mb-16 md:mb-0 items-center md:w-full">
                            <p className="font-semibold   text-white w-full">Название: <span className="font-light">{hardcoreServerData.name} </span> </p>
                            <p className="font-semibold   text-white w-full mb-4">Карта: <span className="font-light">{findMapByCode(hardcoreServerData.map)?.name ?? hardcoreServerData.map}</span></p>


                            {/* Кнопка копирования IP */}
                            <div className="flex flex-col sm:flex-row items-center mb-4">
                                <input
                                    type="text"
                                    readOnly
                                    className="border rounded-lg p-2 bg-white text-gray-700 w-full sm:w-auto text-center mb-2 sm:mb-0"
                                    value={ipHard}
                                />
                                <button
                                    className="bg-gray-100 inline-flex py-2 px-5 rounded-lg items-center hover:bg-gray-200 focus:outline-none sm:ml-2"
                                    onClick={() => handleCopyToClipboardHardcore(ipHard)}
                                >
                                    {isCopiedHardcore ? (
                                        <>
                                            <CheckCircleIcon className="w-6 h-6 text-green-500" />
                                            <span className="ml-4 text-green-500">Скопировано!</span>
                                        </>
                                    ) : (
                                        <>
                                            <ClipboardDocumentIcon className="w-6 h-6" />
                                            <span className="ml-4">Скопировать</span>
                                        </>
                                    )}
                                </button>
                            </div>
                        </div>

                        {/* Изображение карты для модифицированного сервера */}
                        <div className="lg:max-w-52 lg:w-full md:w-full w-3/6">
                            <img
                                className="object-cover object-center rounded"
                                src={getMapImagePath(findMapByCode(hardcoreServerData.map))}
                                alt={findMapByCode(hardcoreServerData.map)?.name ?? ''}
                            />
                        </div>
                    </div>

                    {/* Блок с бомж сервером */}
                    <div className="flex px-5 pt-4 md:flex-row flex-col items-center  max-w-4xl">
                        <div className="lg:flex-grow md:w-1/2 md:pr-16 flex flex-col md:items-start md:text-left mb-16 md:mb-0 items-center md:w-full">
                            <p className="font-semibold   text-white w-full">Название: <span className="font-light">{bomjServerData.name} </span> </p>
                            <p className="font-semibold   text-white w-full mb-4">Карта: <span className="font-light">{findMapByCode(bomjServerData.map)?.name ?? bomjServerData.map}</span></p>


                            {/* Кнопка копирования IP */}
                            <div className="flex flex-col sm:flex-row items-center mb-4">
                                <input
                                    type="text"
                                    readOnly
                                    className="border rounded-lg p-2 bg-white text-gray-700 w-full sm:w-auto text-center mb-2 sm:mb-0"
                                    value={ipBomj}
                                />
                                <button
                                    className="bg-gray-100 inline-flex py-2 px-5 rounded-lg items-center hover:bg-gray-200 focus:outline-none sm:ml-2"
                                    onClick={() => handleCopyToClipboardBomj(ipBomj)}
                                >
                                    {isCopiedBomj ? (
                                        <>
                                            <CheckCircleIcon className="w-6 h-6 text-green-500" />
                                            <span className="ml-4 text-green-500">Скопировано!</span>
                                        </>
                                    ) : (
                                        <>
                                            <ClipboardDocumentIcon className="w-6 h-6" />
                                            <span className="ml-4">Скопировать</span>
                                        </>
                                    )}
                                </button>
                            </div>
                        </div>

                        {/* Изображение карты для модифицированного сервера */}
                        <div className="lg:max-w-52 lg:w-full md:w-full w-3/6">
                            <img
                                className="object-cover object-center rounded"
                                src={getMapImagePath(findMapByCode(bomjServerData.map))}
                                alt={findMapByCode(bomjServerData.map)?.name ?? ''}
                            />
                        </div>
                    </div>

                </div>

                <div className="mr-auto ml-4">

                    {/* Табы */}
                    <div className="pt-14">
                        <nav className="flex space-x-4" aria-label="Tabs">
                            <button
                                onClick={() => handleTabClick('Активность')}
                                className={`rounded-md px-3 py-2 text-sm font-medium ${activeTab === 'Активность' ? 'bg-gray-100 text-gray-700' : 'text-gray-500 hover:text-gray-700'
                                    }`}
                            >
                                Активность
                            </button>
                            <button
                                onClick={() => handleTabClick('Топ недели')}
                                className={`rounded-md px-3 py-2 text-sm font-medium ${activeTab === 'Топ недели' ? 'bg-gray-100 text-gray-700' : 'text-gray-500 hover:text-gray-700'
                                    }`}
                            >
                                Топ недели
                            </button>
                            <button
                                onClick={() => handleTabClick('Топ месяца')}
                                className={`rounded-md px-3 py-2 text-sm font-medium ${activeTab === 'Топ месяца' ? 'bg-gray-100 text-gray-700' : 'text-gray-500 hover:text-gray-700'
                                    }`}
                            >
                                Топ месяца
                            </button>
                        </nav>
                    </div>

                    {/* Контент табов */}
                    <div className="mt-8 flow-root max-h-96 min-w-80 max-w-6xl overflow-y-auto bg-gray-800 rounded-lg scrollbar">
                        <ul role="list">
                            {activeTab === 'Активность' && renderPlayers(results)}
                            {activeTab === 'Топ недели' && (
                                loadingWeekly ? <p>Loading...</p> : (
                                    <>
                                        {topWeekly.length > 0 && renderTopHeader()}
                                        {renderTop(topWeekly)}
                                    </>
                                )
                            )}
                            {activeTab === 'Топ месяца' && (
                                loadingMonthly ? <p>Loading...</p> : (
                                    <>
                                        {topMonthly.length > 0 && renderTopHeader()}
                                        {renderTop(topMonthly)}
                                    </>
                                )
                            )}
                        </ul>
                    </div>

                </div>


            </div>


        </section>
    );
};

export default Example;
